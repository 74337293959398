<template>
     <v-dialog v-model="open" persistent content-class="modal70">
     <div >
      <v-sheet 
       elevation="4"
      style="padding: 20px 20px 40px"
     >
      


         <!-- cabecera titulo btn cerrar-->
      
      <div class="conflex" style="justify-content: space-between;align-items:center">
        <h2 style="color: grey">OBSERVACIONES</h2>
        <v-btn
          style="margin-left: 10px"
          v-bind="$cfg.btra_cfg.standard"
          title="Cerrar"
          @click="closeWindow()"
        >
          <v-icon>{{ "mdi-close" }}</v-icon>
        </v-btn>
      </div>

        <!-- fin cabecera  -->
        <div
          v-show="edicion"
          class="columna"
          style="margin-left: 120px; margin-bottom: 10px"
        >
          <div style="display: flex">
            <v-textarea
              v-model="valueaux"
              style="width: 850px"
              label="Introduzca observaciones aqui:"
              :disabled="!edicion"
              rows="6"
              :auto-focus="true"
              :no-resize="true"
              clearable
              clear-icon="mdi-close"
              filled
              @click:clear="clearText()"
            >
              <!-- v-model="schemaComponente.valueaux" -->
            </v-textarea>
          </div>
        </div>
        <!--Area de mensajes -->
        <ctrlobs3Mensajes
          :arrMensajes="arrMensajes"
          :rolesMensajes="rolesMensajes"
          :rolesTitulo="rolesTitulo"
          :schemaComponente="schemaComponente"
          :agrupar="agrupar"
        >
        </ctrlobs3Mensajes>
      </v-sheet>
    </div>
  </v-dialog>
</template>
<script>
import plugs from "@/common/general_plugs";
const ctrlobs3Mensajes = () => plugs.groute("ctrlobs3Mensajes.vue", "comp");
export default {
  name: "ctrlobs3Motivos",
  components: { ctrlobs3Mensajes },
  props: {
    open: { type: Boolean, default: true },
    edicion: { type: Boolean, default: false },
    schemaComponente: { type: [Object, Array] },

    agrupar: { type: Boolean, default: false },
    arrMensajes: { type: [Object, Array], required: true },
    rolesMensajes: { type: [Object, Array], required: true },
    rolesTitulo: { type: [Object, Array], required: true },
  },

  data() {
    return {
     valueaux:'',
      // variables de configuración
      Entorno: this.$cfg.ctrls.ctrlObs,
    };
  },
    watch: {
      'schemaComponente.comp.valueaux': {
        immediate: true,
         handler () {
          this.valueaux=this.schemaComponente.comp.valueaux

        },
      },
     },
  methods: {
    clearText() {

      this.valueaux = '';
    },
    closeWindow() {
      if (this.valueaux === null) this.valueaux='';
      //this.schemaComponente.comp.valueaux=ev.data.texto;
     this.$emit("update", { obsComp: "ctrlobs3Texto", data: {valueaux:this.valueaux+''}});
    },
  },
};
</script>
