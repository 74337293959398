import { render, staticRenderFns } from "./ctrlobs3Texto.vue?vue&type=template&id=8de9f6fc"
import script from "./ctrlobs3Texto.vue?vue&type=script&lang=js"
export * from "./ctrlobs3Texto.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports